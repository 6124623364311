import { createApp } from 'vue'
import { setupRouter } from './router'
import store from './store'
import graphql from './plugins/graphql'
import { setupI18n } from './plugins/i18n'
import ant from './plugins/antd-ui'
import App from './App.vue'

const i18n = setupI18n()
const router = setupRouter(i18n)

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(graphql, { endpoint: process.env.VUE_APP_API_URL + '/graphql' })
  .use(ant)
  .mount('#app')
