import { createRouter, createWebHistory } from 'vue-router'
import { setI18nLanguage } from '../plugins/i18n'
import Home from '../views/Home.vue'
import Revocation from '../views/Revocation.vue'
import TnC from '../views/TnC.vue'

export function setupRouter(i18n) {
  const SUPPORT_LOCALES = ['en', 'de']
  const { global: composer } = i18n

  // setup routes
  const routes = [
    {
      path: '/l/:id',
      name: 'home',
      component: Home,
    },
    {
      path: '/',
      name: 'index',
      redirect: () => `/l/invalid`,
    },
    {
      path: '/l/:id/revocation',
      name: 'revocation',
      component: Revocation,
    },
    {
      path: '/l/:id/tnc',
      name: 'tnc',
      component: TnC,
    },
  ]

  // create router instance
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  // navigation guards
  router.beforeEach(async (to, from, next) => {
    const locale = to.params.locale || 'de'

    // check locale
    if (!SUPPORT_LOCALES.includes(locale)) {
      return false
    }

    // load locale messages
    if (!composer.availableLocales.includes(locale)) {
      const messages = await import(
        /* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`
      )
      composer.setLocaleMessage(locale, messages.default)
    }

    // set i18n language
    setI18nLanguage(i18n, locale)

    return next()
  })

  return router
}
