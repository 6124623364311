<template>
  <div class="footer" :class="{ 'footer--mobile-hidden': !showForMobile }">
    <a
      data-cy="drs_create_reminder_btn"
      class="footer__button"
      @click.prevent="$emit('add-item')"
    >
      <PlusOutlined class="icon" />
      <span>{{ text }}</span>
    </a>
  </div>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    PlusOutlined,
  },
  props: {
    text: { type: String, default: '' },
    showForMobile: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  &--mobile-hidden {
    display: none;
  }

  @include breakpoint(sm) {
    display: inline-block;
  }

  &__button {
    @include text(2);

    margin: 0;
    cursor: pointer;
    color: $gray-7;
    display: flex;
    align-items: center;
    padding: spacing(2) 0;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;

    .icon {
      transition: background-color 0.2s ease-in, color 0.2s ease-in;
      color: $blue-6;
      margin-right: spacing(2);
      border-radius: 50%;
      padding: spacing(2);
    }

    &:hover {
      color: $blue-6;

      .icon {
        background-color: $blue-6;
        color: $white;
      }
    }

    &:active {
      color: $blue-8;

      .icon {
        background-color: $blue-8;
        color: $white;
      }
    }
  }
}
</style>
