<template>
  <div class="container">
    <div class="content">
      <div class="top">
        <a href="https://reo.so"
          ><img alt="REO logo" src="../assets/images/Reo-Logotype-blue.svg"
        /></a>
      </div>
      <div class="main">
        <template v-if="documentLoading">
          <div class="loading-icon">
            <LoadingOutlined
              :style="{ 'font-size': '30px', color: '#2265F1' }"
            />
          </div>
        </template>
        <template v-else>
          <div v-if="currentStep === steps.EXPIRED" class="expired">
            <h1
              class="heading--small"
              v-text="$t('docs.expiredLink.title')"
            ></h1>
            <p v-text="$t('docs.expiredLink.subtitle')"></p>
          </div>

          <div v-else-if="currentStep === steps.INVALID" class="invalid">
            <h1 class="heading--small">{{ $t('docs.notFound') }}</h1>
            <p v-text="$t('docs.notFound.subtitle')"></p>
          </div>

          <div v-else-if="currentStep === steps.FORM" class="form">
            <h1
              v-if="!multiple"
              class="heading"
              v-text="$t('docs.welcome.message', { brokerName, fileName })"
            />
            <h1
              v-else
              class="heading"
              v-text="$t('docs.files') + ' ' + fileName"
            />

            <div v-if="showList" class="list">
              <div>
                <div
                  v-for="item in userList"
                  :key="item.email + item.fullName"
                  class="item"
                  @click="handleClick(item)"
                >
                  <div>
                    <b> {{ item.fullName }} </b>
                    <div>{{ item.email }}</div>
                  </div>
                  <RightOutlined />
                </div>
              </div>
              <base-add-item
                :text="$t('docs.identification.useOther')"
                showForMobile
                @add-item="addAnother"
              />
            </div>
            <form v-else @submit.prevent="download">
              <input
                v-model="fullName"
                ref="firstName"
                name="fullName"
                required
                :placeholder="$t('docs.identification.namePlaceholder')"
                class="input"
              />
              <input
                v-model="email"
                name="email"
                type="email"
                required
                :placeholder="$t('docs.identification.emailPlaceholder')"
                class="input"
              />
              <div class="remember-me-wrapper">
                <input v-model="rememberMe" id="remeber-me" type="checkbox" />
                <label
                  v-t="'docs.identification.rememberCheckbox'"
                  for="remeber-me"
                />
              </div>
              <a-button
                type="primary"
                html-type="input"
                block
                v-text="$t('docs.identification.continue')"
                class="button"
              />
            </form>
            <div class="right-of-revocation">
              <i18n-t keypath="docs.revocation" tag="div">
                <template #link>
                  <span class="right-of-revocation__link" @click="showRights">{{
                    $t('docs.revocation.link')
                  }}</span>
                </template>
              </i18n-t>
            </div>
          </div>
          <div v-else class="download">
            <h1 class="heading--small">
              {{ $t('docs.download.success') }}
            </h1>
            <a @click="download">
              {{ $t('docs.download.manualDownload') }}
            </a>
          </div>
        </template>
      </div>
      <div class="bottom">
        <a
          v-bind:href="$t('common.link.dataPolicy')"
          v-text="$t('common.text.dataPolicy')"
          target="_blank"
        ></a>
        <a
          :href="$t('common.link.imprint')"
          v-text="$t('common.text.imprint')"
          target="_blank"
        ></a>
      </div>
    </div>
    <div class="image"></div>
  </div>
</template>

<script>
import BaseAddItem from '@/components/BaseAddItem.vue'
import { RightOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { ref } from 'vue'
import { documentGQL } from '@/gql/app.gql'

export default {
  name: 'Home',
  components: { BaseAddItem, RightOutlined, LoadingOutlined },
  beforeMount() {
    if (localStorage.getItem('infoList')) {
      try {
        this.userList = JSON.parse(localStorage.getItem('infoList'))
        if (this.userList?.length) this.showList = true
      } catch (e) {
        localStorage.removeItem('infoList')
      }
    }
  },
  async mounted() {
    const fileId = this.$route.params.id
    const variables = {
      link: fileId,
    }

    try {
      this.documentLoading = true
      const file = await this.$graphql.request(documentGQL, variables)
      this.brokerName = `${file.fileLink.createdBy.firstName} ${file.fileLink.createdBy.lastName}`

      const address =
        file.fileLink.property?.addresses[
          Object.keys(file.fileLink.property.addresses)[0]
        ]

      if (file.fileLink.file && file.fileLink.file?.name) {
        this.fileName = file.fileLink.file?.name
      } else if (
        file.fileLink.files &&
        Object.keys(file.fileLink.files).length > 1
      ) {
        this.multiple = true
        if (address) {
          this.fileName = `${address.street} ${address.streetNo}, ${address.zip} ${address.city}`
        } else {
          this.fileName = this.brokerName
        }
      } else {
        const obj = file.fileLink.files
        const first = obj[Object.keys(obj)[0]]
        this.fileName = first?.name
      }

      if (file.fileLink.isPublic && !file.fileLink.expiresAt) {
        this.currentStep = this.steps.FORM
      } else this.isExpired(file.fileLink.expiresAt, this.fileName)
      this.fileUrl = fileId

      this.setDocumentTitle(this.fileName)
    } catch {
      return false
    } finally {
      this.documentLoading = false
    }
  },
  data() {
    return {
      showList: false,
      userList: [],
      rememberMe: true,
      multiple: false,
    }
  },
  setup() {
    const steps = ref({
      EXPIRED: 1,
      INVALID: 2,
      FORM: 3,
      LIST: 4,
      DOWNLOAD: 5,
    })
    const fullName = ref('')
    const brokerName = ref('')
    let email = ref('')
    let checked = ref(false)
    const fileName = ref('')
    const fileUrl = ref('')
    const revocationText = ref('')
    let currentStep = ref(steps.value.INVALID)

    const documentLoading = ref(true)

    const isExpired = (expiredDate, name) => {
      const today = new Date()
      if (today.getTime() > expiredDate) {
        currentStep.value = steps.value.EXPIRED
      } else if (name || today.getTime() <= expiredDate) {
        currentStep.value = steps.value.FORM
      } else {
        currentStep.value = steps.value.INVALID
      }
    }

    return {
      fileUrl,
      fileName,
      fullName,
      brokerName,
      email,
      checked,
      steps,
      currentStep,
      isExpired,
      revocationText,
      documentLoading,
    }
  },
  methods: {
    async download() {
      const fileId = this.$route.params.id
      const url = `${process.env.VUE_APP_API_URL}/api/file-links/${fileId}/download?name=${this.fullName}&email=${this.email}`

      window.open(url, '_blank', 'noreferrer')

      if (this.rememberMe) {
        this.userList.push({ fullName: this.fullName, email: this.email })
        localStorage.setItem('infoList', JSON.stringify(this.userList))
        this.rememberMe = false // prevent double saving
      }

      this.currentStep = this.steps.DOWNLOAD
    },
    handleClick(item) {
      this.fullName = item.fullName
      this.email = item.email
      this.rememberMe = false
      this.download()
    },
    addAnother() {
      this.showList = false
      this.$nextTick(() => {
        this.$refs?.firstName.focus()
      })
    },
    setDocumentTitle(title) {
      if (!document) return
      document.title = title
    },
    showRights() {
      this.$router.push({
        name: 'revocation',
        params: { id: this.$route.params.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;

  .content {
    display: flex;
    flex: 0 1 542px;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    padding: 49px 24px 23px 24px;
    margin: auto;
    word-break: break-word;

    @include breakpoint(sm) {
      padding: 49px 81px 23px 71px;
    }
  }

  .heading {
    @include text(3, bold);

    margin-bottom: 26px;

    &-small {
      @include text(2);
    }
  }

  .form .input {
    margin-bottom: 11px;
    height: 41px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 14px;
    width: 100%;
    padding: 0 10px;
  }

  .button {
    height: 41px;
  }

  .image {
    display: flex;
    height: 100%;
    width: 100%;
    flex: 1 0 542px;
    background-image: url('../assets/images/background.jpg');
    background-size: cover;
  }

  .bottom,
  .top img {
    height: 24px;
  }

  a {
    color: $gray-7;
    padding-right: 10px;
  }
}

.list {
  .item {
    display: flex;
    padding: spacing(4) spacing(2);
    cursor: pointer;

    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-5;
    }

    & > div {
      flex: 1;
    }

    & > span {
      padding: spacing(1);
      align-self: center;
    }

    &:hover {
      background: $gray-2;
    }
  }
}

.loading-icon {
  text-align: center;
}

.remember-me-wrapper {
  display: flex;
  margin: 0 spacing(-1) spacing(4);
  align-items: center;

  & > * {
    margin: 0 spacing(1);
  }
}

.right-of-revocation {
  @include text(2);
  text-align: left;
  color: $gray-7;
  margin-top: 16px;

  &__link {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: $gray-8;
    }
  }
}
</style>
