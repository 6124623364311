import antd from 'ant-design-vue'

const ant = {
  install(App) {
    App.component(antd.Icon.name, antd.Icon)
    App.component(antd.Button.name, antd.Button)
    App.component(antd.Input.name, antd.Input)
    App.component(antd.Checkbox.name, antd.Checkbox)
  },
}
export default ant
