<template>
  <div class="container">
    <div class="content">
      <div class="top">
        <a href="https://reo.so"
          ><img alt="REO logo" src="../assets/images/Reo-Logotype-blue.svg"
        /></a>
        <h2>{{ $t('expose.legal.revocation') }}</h2>
      </div>
      <div class="main" v-html="text"></div>
      <div class="bottom">
        <a
          v-bind:href="$t('common.link.dataPolicy')"
          v-text="$t('common.text.dataPolicy')"
          target="_blank"
        ></a>
        <a
          :href="$t('common.link.imprint')"
          v-text="$t('common.text.imprint')"
          target="_blank"
        ></a>
      </div>
    </div>
    <div class="image"></div>
  </div>
</template>

<script>
import { documentGQL, teamGQL } from '@/gql/app.gql'

export default {
  async created() {
    this.$i18n.locale = this.$route.query.lang || 'de'
    const id = this.$route.params.id
    const type = this.$route.query.context
    if (type === 'team') {
      this.fetchTeamData(id)
      return
    }
    this.fetchFileData(id)
  },
  data() {
    return {
      text: '',
    }
  },
  methods: {
    async fetchFileData(fileId) {
      const variables = {
        link: fileId,
      }

      try {
        const file = await this.$graphql.request(documentGQL, variables)
        this.text = file.propertyFileLink.createdBy.team.revocationText
      } catch (err) {
        console.error(err)
      }
    },
    async fetchTeamData(teamId) {
      const variables = {
        id: teamId,
      }

      try {
        const teamDetails = await this.$graphql.request(teamGQL, variables)
        const revocationText = teamDetails.team.exposeLegalTexts.find(
          (el) => el.name === 'expose.legal.revocation'
        ).text

        if (revocationText) {
          this.text = revocationText
        }
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;

  .content {
    display: flex;
    flex: 0 1 542px;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    padding: 49px 24px 23px 24px;
    margin: auto;
    word-break: break-word;

    @include breakpoint(sm) {
      padding: 49px 51px 23px 71px;
    }
  }

  .image {
    display: flex;
    height: 100%;
    width: 100%;
    flex: 1 0 542px;
    background-image: url('../assets/images/background.jpg');
    background-size: cover;
  }

  .main {
    overflow-x: auto;
    height: 100%;
    margin-bottom: 30px;
    padding-right: 30px;
  }

  .top {
    h2 {
      margin-top: 80px;
    }
  }

  .bottom,
  .top img {
    height: 24px;
  }

  a {
    color: $gray-7;
    padding-right: 10px;
  }
}
</style>
